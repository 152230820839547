import {
  ComponentClientSpecMapEntry,
  ComponentRef,
  EditorSDK,
} from '@wix/platform-editor-sdk';
import { OwnerLogger } from '@wix/yoshi-flow-editor';
import application from '../../.application.json';
import {
  formWidgetCrud,
  publishedFormsWidgets,
} from '@wix/bi-logger-form-app/v2';
import { NAMESPACE } from '../constants/namespace';
import { FORM_TEMPLATES, FormAppPreset } from '../constants/templates';

interface ReportPublishedWidgetsParams {
  sdk: EditorSDK;
  appToken: string;
  bi: OwnerLogger;
}

interface Component extends ComponentClientSpecMapEntry {
  tpaData?: {
    content?: string;
  };
}

export const reportPublishedWidgets = async ({
  sdk,
  appToken,
  bi,
}: ReportPublishedWidgetsParams) => {
  try {
    const msid = await sdk.info.getMetaSiteId(appToken);
    const data = await sdk.document.tpa.app.getDataByAppDefId(
      appToken,
      application.appDefinitionId,
    );

    const components = await sdk.document.tpa.app.getAllCompsByApplicationId(
      appToken,
      data.applicationId,
    );

    const widgets = components.map((component: Component) => {
      const tpaData = component.tpaData?.content;
      const formId = tpaData ? JSON.parse(tpaData)?.formId : undefined;
      const { widgetId, id, pageId } = component;

      return {
        widgetId,
        id,
        pageId,
        formId,
      };
    });

    bi.report(
      publishedFormsWidgets({
        namespace: NAMESPACE,
        forms_object: JSON.stringify(widgets),
        biToken: msid,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

export const reportAddedWidget = ({
  component,
  presetId,
  bi,
  msid,
}: {
  component: ComponentClientSpecMapEntry;
  presetId: FormAppPreset;
  bi: OwnerLogger;
  msid: string;
}) => {
  bi.report(
    formWidgetCrud({
      action: 'added',
      component_id: component.id,
      form_guid: [FormAppPreset.Blank, FormAppPreset.Existing].includes(
        presetId,
      )
        ? null
        : FORM_TEMPLATES[presetId],
      namespace: NAMESPACE,
      origin: 'add panel',
      template: presetId,
      widget_id: component.widgetId,
      biToken: msid,
    }),
  );
};

export const reportDeletedWidget = ({
  componentRef,
  bi,
  msid,
}: {
  componentRef: ComponentRef;
  bi: OwnerLogger;
  msid: string;
}) => {
  bi.report(
    formWidgetCrud({
      action: 'removed',
      component_id: componentRef.id,
      namespace: NAMESPACE,
      biToken: msid,
    }),
  );
};
