import {
  FormAppPreset,
  FORM_TEMPLATES,
  FORM_TEMPLATE_NAMES,
} from '../constants/templates';
import {
  getFormAppComponents,
  setComponentFormId,
  getComponentPresetId,
  getComponentFormId,
  handleBlankTemplate,
  handlePresetTemplate,
} from './utils';
import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { IHttpClient, OwnerLogger } from '@wix/yoshi-flow-editor';
import { queryForms, createForm } from '@wix/ambassador-forms-v4-form/http';
import { Kind } from '@wix/ambassador-forms-v4-form/types';
import { NAMESPACE } from '../constants/namespace';
import {
  reportPublishedWidgets,
  reportAddedWidget,
  reportDeletedWidget,
} from './bi';

interface OnWidgetAddedParams {
  sdk: EditorSDK;
  appToken: string;
  httpClient: IHttpClient;
  bi: OwnerLogger;
}

export const onWidgetAdded = ({
  sdk,
  appToken,
  httpClient,
  bi,
}: OnWidgetAddedParams) =>
  sdk.addEventListener(
    'widgetAdded',
    async ({ detail: { componentRef, originalComponentId } }) => {
      const components = await getFormAppComponents({ sdk, appToken });
      const component = components?.find((item) => item.id === componentRef.id);
      const presetId = getComponentPresetId(component);
      const msid = await sdk.info.getMetaSiteId(appToken);

      reportAddedWidget({
        component,
        presetId,
        bi,
        msid,
      });

      if (originalComponentId) {
        return;
      }

      if (presetId === FormAppPreset.Blank || presetId === undefined) {
        await handleBlankTemplate({
          appToken,
          sdk,
          httpClient,
          componentRef,
          presetId,
        });
      } else if (presetId === FormAppPreset.Existing) {
        await setTimeout(
          async () =>
            sdk.editor.openSettingsPanel(appToken, {
              componentRef,
            }),
          3000,
        );
      } else {
        if (presetId) {
          await handlePresetTemplate({
            appToken,
            sdk,
            httpClient,
            componentRef,
            presetId,
          });
        }
      }
    },
  );

interface OnSiteSavedParams {
  sdk: EditorSDK;
  appToken: string;
  httpClient: IHttpClient;
}

export const onSiteSaved = ({ sdk, appToken, httpClient }: OnSiteSavedParams) =>
  sdk.addEventListener('siteWasSaved', async () => {
    const components = await getFormAppComponents({ sdk, appToken });
    const componentsWithFormsToCreate = components?.filter((component) =>
      Object.values(FORM_TEMPLATES).includes(getComponentFormId(component)),
    );

    if (componentsWithFormsToCreate?.length) {
      componentsWithFormsToCreate.forEach(async (component) => {
        const templateFormId = getComponentFormId(component);
        const formToCreate = await httpClient.request(
          queryForms({
            query: {
              filter: {
                id: { $eq: templateFormId },
                namespace: { $eq: NAMESPACE },
              },
            },
            kind: Kind.EXTENSION,
          }),
        );

        const response = await httpClient.request(
          createForm({
            form: {
              ...formToCreate.data.forms[0],
              properties: {
                name: FORM_TEMPLATE_NAMES[templateFormId],
                disabled: false,
              },
              kind: Kind.REGULAR,
            },
          }),
        );

        await setComponentFormId({
          appToken,
          sdk,
          formId: response.data.form.id,
          compRef: { id: component.id, type: 'DESKTOP' },
        });
      });
    }
  });

interface OnComponentDeletedParams {
  sdk: EditorSDK;
  bi: OwnerLogger;
  appToken: string;
}

export const onComponentDeleted = ({
  sdk,
  bi,
  appToken,
}: OnComponentDeletedParams) =>
  sdk.addEventListener(
    'componentDeleted',
    async ({ detail: { componentRef } }) => {
      const msid = await sdk.info.getMetaSiteId(appToken);
      reportDeletedWidget({ componentRef, bi, msid });
    },
  );

interface OnSitePublishedParams {
  sdk: EditorSDK;
  appToken: string;
  bi: OwnerLogger;
}

export const onSitePublished = ({ sdk, appToken, bi }: OnSitePublishedParams) =>
  sdk.addEventListener('siteWasPublished', async () => {
    await reportPublishedWidgets({ sdk, appToken, bi });
  });
